import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeBackground from "../components/Visuals/homeBackground"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import JumboComponent from "../components/JumboComponent"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import HomeContactComponent from "../components/HomeContactComponent"
import KisokosBox from "../components/KisokosBox"
import TarsadalmiOrgsComponent from "../components/TarsadalmiOrgsComponent"

const StyledSeparator = styled.div`
  width: 100%;
  display: block;
  height: 55vh;
  position: relative;
`

const KisokosPage = props => {
  const { i18n } = useTranslation()
  const language = i18n.language
  const mainPageData = props.data.sanityHomePage
  const thisPageData = props.data.sanityKisokosPage
  const pageTitle = thisPageData._rawTitle[language]
  const seoImage = getImage(thisPageData.image)?.images.fallback.src
  return (
    <Layout alternateLink={props.alternateLinks}>
      <HomeBackground />
      <Container>
        <Seo
          title={`Cseriti - ${pageTitle}`}
          lang={language}
          image={seoImage}
        />
        <JumboComponent
          images={thisPageData.image}
          slogan={thisPageData._rawTitle[language]}
          singleImage={true}
        />
      </Container>
      {thisPageData.boxes.map(box => (
        <>
          <KisokosBox
            box={box}
            language={language}
            key={mainPageData.id + box._rawTitle[language]}
          />
          <StaticImage
            style={{
              gridArea: "1/1",
              zIndex: -1,
              position: "absolute",
              height: "25vh",
              width: "100%",
              top: 0,
              left: 0,
            }}
            alt=""
            placeholder="blurred"
            src={"../images/Hattercsik.jpg"}
            formats={["auto", "webp", "avif"]}
          />
        </>
      ))}

      {/* <StyledSeparator>
        <Container>
          <Row>
            <Col xs={12} className="px-0">
              <GatsbyImage
                style={{
                  gridArea: "1/1",
                  height: "55vh",
                  width: "100%",
                  top: 0,
                  left: 0,
                }}
                alt=""
                placeholder="blurred"
                image={getImage(
                  thisPageData.imageDivider?.asset?.gatsbyImageData
                )}
                formats={["auto", "webp", "avif"]}
              />
            </Col>
          </Row>
        </Container>
      </StyledSeparator> */}

      {/* <VallalatoknakBox
        box={thisPageData.boxes[1]}
        language={language}
        key={mainPageData.id + thisPageData.boxes[1]._rawTitle[language]}
      /> */}

      <TarsadalmiOrgsComponent
        title={"Támogatott szervezetek:"}
        organisations={thisPageData.organisations}
      />
      <HomeContactComponent intro={mainPageData._rawIntro[language]} />
    </Layout>
  )
}

export const query = graphql`
  query KisokosPageQuery {
    sanityHomePage(_id: { eq: "d653ce08-c183-4893-a241-7b5d7d931ee6" }) {
      id
      _rawIntro
      mainImages {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      _rawSlogan
    }
    sanityKisokosPage {
      _rawTitle
      _rawOrganisationTitle
      image {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      boxes {
        _rawCalloutTitle
        _rawDescription
        _rawTitle
        imageDivider {
          asset {
            gatsbyImageData(width: 800, layout: CONSTRAINED)
          }
        }
      }
      organisations {
        name
        url
        logo {
          asset {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
export default KisokosPage
