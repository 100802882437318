import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import SanityBlockContent from "@sanity/block-content-to-react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { sanitySerializers, sanityClientConfig } from "../../utils/sanityConfig"
import { MediaQueries } from "../../utils/responsive"

const StyledBox = styled(Container)`
  margin-top: ${props => `${props.theme.margins.verticalDesktop * 1.2}px`};
  margin-bottom: ${props => `${props.theme.margins.verticalDesktop * 2}px`};
  .box-title {
    font-size: 3.2rem;
    color: ${props => props.theme.colors.pink};
    margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
    text-transform: uppercase;
    line-height: 1.45;
    @media ${MediaQueries.mobile} {
      max-width: 550px;
      font-size: 4.6rem;
    }
  }
  .box-description {
    text-align: justify;
    .highlight {
      color: ${props => props.theme.colors.pink};
    }
    @media ${MediaQueries.mobile} {
      width: 75%;
    }
  }

  .callout-title {
    line-height: 1.5;
    color: ${props => props.theme.colors.pink};
    font-family: "Anton";
    margin-bottom: 0;
    padding-bottom: 2rem;
    @media ${MediaQueries.mobile} {
      font-size: 28px;
    }
  }
  .callout-email {
    text-decoration: underline;
    margin-bottom: 0;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .callout-name {
    margin-bottom: 0;
    color: ${props => props.theme.colors.pink};
    font-size: 2.4rem;
    padding: 3rem 0;
  }
`

const KisokosBox = ({ box, language }) => {
  return (
    <StyledBox>
      <Row>
        <Col xs={12}>
          <h2 className="box-title">{box._rawTitle[language]}</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <SanityBlockContent
            key={(Math.random() * 1000 + 12).toString()}
            blocks={box._rawDescription[language]}
            projectId={sanityClientConfig.sanity.projectId}
            dataset={sanityClientConfig.sanity.dataset}
            serializers={sanitySerializers}
            className="box-description"
          />
        </Col>
        <Col xs={12} md={4}>
          <p className="callout-title">{box._rawCalloutTitle[language]}</p>
        </Col>
        <Col xs={12}>
          <GatsbyImage
            style={{
              gridArea: "1/1",
              height: "55vh",
              width: "100%",
              top: 0,
              left: 0,
            }}
            alt=""
            placeholder="blurred"
            image={getImage(box.imageDivider?.asset?.gatsbyImageData)}
            formats={["auto", "webp", "avif"]}
          />
        </Col>
      </Row>
    </StyledBox>
  )
}

export default KisokosBox
